<template>
  <div v-if="cartData.length>0||expiredCourse.length>0" class="cart-page">
    <div class="order-info">
      <div class="order-desc">
        <div class="choose-all">
          <i class="iconfont"
             :class="selectArr.length === cartData.length&&selectArr.length!==0? 'icon-papa-buy_selected':'icon-papa-buy_select_none'"
             @click="selectProduct('all')">全选</i>
        </div>
        <div class="course-title">课程</div>
        <div class="course-attr">属性</div>
        <div class="course-price">价格(课/元)</div>
        <div class="course-amount">数量</div>
        <div class="course-sub-price">小计(元)</div>
        <div class="course-delete">操作</div>
      </div>
      <div class="order-list">
        <div class="order-item" v-for="(item,index) in cartData" :key="item.id">
          <div class="choose-icon" @click="selectProduct(item.id)" v-show="selectArr.indexOf(item.id)<0">
            <i class="iconfont icon-papa-buy_select_none"></i>
          </div>
          <div class="choose-icon" @click="selectProduct(item.id)" v-show="selectArr.indexOf(item.id)>-1">
            <i class="iconfont icon-papa-buy_selected"></i>
          </div>
          <div class="course-pic">
            <img v-if="item.course.cover_image_url" :src="item.course.cover_image_url" alt="">
            <div class="course-title">{{item.course.title}}</div>
          </div>
          <div class="course-attribute" @mouseenter.stop="handleHoverCourse(item.id)" @mouseleave="handleLeveCourse"
               :class="hoverCourseId == item.id? 'course-attribute-border':''">
            <i class="iconfont icon-papa-buy_edit" v-show="hoverCourseId === item.id"
               @click="editCourseAttribute(item)"></i>
            <div class="subject">科目：{{item.course_subject.name}}</div>
            <div class="teacherLevel" v-if="item.teacher_level.name">教师：{{item.teacher_level.name}}</div>
            <div class="property-box" v-show="editId === item.id">
              <div class="course-subject">
                <div class="key">科目</div>
                <div class="value">
              <span v-for="(course) in editAttribute" :key="course.id" :value="course.id"
                    :class="course.id===activeCourse.id?'active':''"
                    @click="computedTeacherLevel(course)">{{course.name}}</span>
                </div>
              </div>
              <div class="teacher-level" v-if="item.course.class_type === 1">
                <div class="key">教师</div>
                <div class="value">
              <span v-for="teacher in editTeacherLevel" :key="teacher.id"
                    :class="teacher.id===activeTeacher.id?'active':''"
                    @click="computedLeastQty(teacher)">{{teacher.name}}</span>
                </div>
              </div>
              <div class="course-price">
                <div class="key">价格</div>
                <div class="value">￥<span class="price">{{activeTeacher.unit_price}}</span></div>
              </div>
              <div class="action-box">
                <div class="submit-button" @click="submitEdit(item)">确认</div>
                <div class="cancel-button" @click="cancelEdit">取消</div>
              </div>
            </div>
          </div>
          <div class="course-price">￥{{item.course_attribute.unit_price}}</div>
          <div class="course-amount" v-if="item.course.class_type === 1">
            <el-input-number v-model="item.buy_qty" size="mini" @change="handleChange(index)"
                             :min="item.course_attribute.least_sale_qty"
                             :max="item.course_attribute.storage"
                             label=""></el-input-number>
          </div>
          <div class="course-amount" v-else>
            <el-input-number v-model="item.buy_qty" size="mini"
                             :min=1*1
                             :max=1*1
                             label=""></el-input-number>
            <span class="buy-qty-tip">限购1件</span>
          </div>
          <div class="course-sub-price">￥{{item.buy_qty*item.course_attribute.unit_price}}</div>
          <div class="delete" @click="deleteCourse(item.id)">
            <img width="24" height="24" src="./img/delete.png" alt="">
          </div>
        </div>
        <div class="order-item expired" v-for="(item,index) in expiredCourse" :key="item.id">
          <div class="mask"></div>
          <div class="choose-icon expired-tag">
            <div>失效</div>
          </div>
          <div class="choose-icon" @click="selectProduct(item.id)" v-show="selectArr.indexOf(item.id)>-1">
            <i class="iconfont icon-papa-buy_selected"></i>
          </div>
          <div class="course-pic">
            <img v-if="item.course.cover_image_url" :src="item.course.cover_image_url" alt="">
            <div class="course-title">{{item.course.title}}</div>
          </div>
          <div class="course-attribute" @mouseenter.stop="handleHoverCourse(item.id)" @mouseleave="handleLeveCourse"
               :class="hoverCourseId == item.id? 'course-attribute-border':''">
            <i class="iconfont icon-papa-buy_edit" v-show="hoverCourseId === item.id"
               @click="editCourseAttribute(item)"></i>
            <div class="subject">科目：{{item.course_subject.name}}</div>
            <div class="teacherLevel">教师：{{item.teacher_level.name}}</div>
            <div class="property-box" v-show="editId === item.id">
              <div class="course-subject">
                <div class="key">科目</div>
                <div class="value">
              <span v-for="(course) in editAttribute" :key="course.id" :value="course.id"
                    :class="course.id===activeCourse.id?'active':''"
                    @click="computedTeacherLevel(course)">{{course.name}}</span>
                </div>
              </div>
              <div class="teacher-level">
                <div class="key">教师</div>
                <div class="value">
              <span v-for="teacher in editTeacherLevel" :key="teacher.id"
                    :class="teacher.id===activeTeacher.id?'active':''"
                    @click="computedLeastQty(teacher)">{{teacher.name}}</span>
                </div>
              </div>
              <div class="course-price">
                <div class="key">价格</div>
                <div class="value">￥<span class="price">{{activeTeacher.unit_price}}</span></div>
              </div>
              <div class="action-box">
                <div class="submit-button" @click="submitEdit(item)">确认</div>
                <div class="cancel-button" @click="cancelEdit">取消</div>
              </div>
            </div>
          </div>
          <div class="course-price">￥{{item.course_attribute.unit_price}}</div>
          <div class="course-amount" v-if="item.course.class_type === 2">
            <el-input-number v-model="item.buy_qty" size="mini"
                             :min=1*1
                             :max=1*1
                             label=""></el-input-number>
            <span class="buy-qty-tip">限购1件</span>
          </div>
          <div class="course-amount" v-else>
            <el-input-number v-model="item.buy_qty" size="mini" @change="handleChange(index)"
                             :min="item.course_attribute.least_sale_qty"
                             :max="item.course_attribute.storage"
                             label=""></el-input-number>
          </div>
          <div class="course-sub-price">￥{{item.buy_qty*item.course_attribute.unit_price}}</div>
          <div class="delete" @click="deleteCourse(item.id)">
            <img width="24" height="24" src="./img/delete.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="order-total">
      <div class="left">
        <i class="iconfont"
           :class="selectArr.length === cartData.length&&selectArr.length!==0? 'icon-papa-buy_selected':'icon-papa-buy_select_none'"
           @click="selectProduct('all')">全选</i>
        <div class="button-delete" @click="deleteCourse()">删除</div>
      </div>
      <div class="right">
        <div class="total-qty">
          已选择<span>{{selectArr.length}}</span>门课程
        </div>
        <div class="total-price">
          合计 <span>￥{{totalMoney}}</span>
        </div>
        <router-link v-if="selectArr.length>0" to="confirmCart">
          <div class="settle-button">结算</div>
        </router-link>
        <router-link v-else to="/cart">
          <div class="settle-button disable">结算</div>
        </router-link>
      </div>
    </div>
    <div class="background"></div>
  </div>
  <div v-else class="no-product">
    <img src="./img/shopping.png" alt="">
    <div class="tip">购物车里还没有宝贝哦</div>
    <router-link to="/">
      <div class="action">去逛逛</div>
    </router-link>
  </div>
</template>

<script>
  import CartJs from './Cart.js'

  export default CartJs
</script>

<style lang="less" scoped>
  /*@import "../../assets/style/lib-base.less";*/

  .cart-page {
    width: 100%;
    height: 100%;
    position: relative;

    & > .order-info {
      width: 1200px;
      margin: 25px auto 20px;
      background: #fff;
      box-shadow: 0px 4px 8px 0px rgba(95, 95, 95, 0.07);
      box-sizing: border-box;
      padding: 30px 40px;

      & > .order-desc {
        display: flex;

        & > .choose-all {
          width: 60px;
          cursor: pointer;

          & > .icon-papa-buy_selected {
            color: #29d087;
          }
        }

        & > .course-title {
          width: 334px;
          margin-right: 40px;
        }

        & > .course-attr {
          width: 180px;
          margin-right: 60px;
        }

        & > .course-price {
          width: 80px;
          margin-right: 40px;
        }

        & > .course-amount {
          width: 130px;
          margin-right: 50px;
        }

        & > .course-sub-price {
          width: 70px;
          margin-right: 40px;
        }
      }

      & > .order-list {
        margin-bottom: 100px;

        & > .expired {
          position: relative;
          color: rgba();
          & > .mask {
            z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: transparent;
          }
          &>.course-pic{
            opacity: .5;
          }
          &>.course-attribute{
            color: rgba(85,85,85,.5)!important;
          }
          &>.course-price{
            color: rgba(85,85,85,.5)!important;
          }
          &>.course-sub-price{
            color: rgba(255,88,100,.5)!important;
          }
          /deep/ .el-input__inner{
            color: rgba(51,51,51,.5);
          }

          & > .expired-tag {
            & > div {
              width: 30px !important;
              height: 16px;
              font-size: 12px;
              color: #666;
              background: #E6E6E6;
              border-radius: 3px;
              text-align: center;
              line-height: 16px;
            }
          }
        }

        & > .order-item {
          display: flex;
          align-items: center;
          text-align: center;
          margin: 35px 0 15px;

          & > .choose-icon {
            width: 60px;
            text-align: left;

            & > .icon-papa-buy_selected {
              color: #29d087;
            }
          }

          & > .course-pic {
            width: 334px;
            display: flex;
            align-items: center;
            margin-right: 40px;
            text-align: left;

            & > img {
              width: 144px;
              max-height: 80px;
              margin-right: 21px;
            }
            &>.course-title{
              font-size: 16px;
              color: #333;
              font-weight: 500;
            }
          }

          & > .course-attribute {
            cursor: pointer;
            width: 180px;
            height: 70px;
            padding: 15px;
            text-align: left;
            margin-right: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            position: relative;
            border: 1px solid transparent;
            font-size: 14px;
            color: #555;

            & > .property-box {
              width: 360px;
              position: absolute;
              padding: 20px;
              display: flex;
              flex-direction: column;
              background: #fff;
              top: -2px;
              left: -2px;
              border: 1px solid rgba(230, 230, 230, 1);
              border-radius: 3px;
              z-index: 2;

              .course-subject, .teacher-level, .action-box, .course-price {
                padding-top: 14px;
                display: flex;
                font-size: @fontSizeCon;

                .key {
                  color: #333;
                  margin-right: 14px;
                  line-height: 30px;
                }

                .value {
                  width: calc(100% - 62px);

                  span {
                    border: 1px solid #d0d0d0;
                    cursor: pointer;
                    display: inline-block;
                    padding: 5px 9px;
                    border-radius: 3px;
                    margin: 0 8px 5px 0;
                    float: left;
                  }

                  .price {
                    color: #FF5864;
                    border: none;
                    cursor: auto;
                    float: none;
                    font-size: 18px;
                    padding: 0;
                  }

                  .active {
                    border: 1px solid @papaGreen;
                    color: @papaGreen;
                  }
                }
              }

              & > .action-box {
                padding-left: 38px;

                & > .submit-button {
                  width: 50px;
                  height: 24px;
                  background: rgba(41, 208, 135, 1);
                  border-radius: 3px;
                  cursor: pointer;
                  margin-right: 15px;
                  text-align: center;
                  line-height: 24px;
                  color: #fff;

                }

                & > .cancel-button {
                  cursor: pointer;
                  width: 50px;
                  height: 24px;
                  cursor: pointer;
                  margin-right: 15px;
                  text-align: center;
                  line-height: 24px;
                }
              }
            }

          }

          & > .course-attribute-border {
            border: 1px solid #e6e6e6;
            border-radius: 3px;

            & > .icon-papa-buy_edit {
              width: 18px;
              height: 18px;
              background: #e6e6e6;
              position: absolute;
              top: 0;
              right: 0;
              color: #fff;
              font-size: 12px;
              text-align: center;
              line-height: 18px;
            }

            & > .icon-papa-buy_edit:before {
              margin-right: 0;
            }
          }

          & > .course-price {
            width: 80px;
            margin-right: 40px;
            text-align: left;
            font-size: 16px;
            color: #555;
            font-weight: bold;
          }

          & > .course-amount {
            width: 130px;
            margin-right: 50px;
            position: relative;

            & > .buy-qty-tip {
              font-size: 14px;
              color: rgb(153, 153, 153);
              position: absolute;
              width: 100%;
              left: 0;
              top: 32px;
            }
          }

          & > .course-sub-price {
            width: 70px;
            margin-right: 40px;
            color: #FF5864;
            font-size: 18px;
            font-weight: bold;
          }

          & > .delete {
            cursor: pointer;
            color: #EFEFEF;
            z-index: 10;
          }
        }

      }
    }

    & > .order-total {
      z-index: 999;
      position: fixed;
      bottom: 20px;
      left: 50%;
      width: 1200px;
      height: 80px;
      background: #fff;
      margin-left: -600px;
      box-shadow: 0px 4px 8px 0px rgba(95, 95, 95, 0.07);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      box-sizing: border-box;

      & > .left {
        display: flex;

        & > .icon-papa-buy_select_none {
          margin-right: 35px;
          cursor: pointer;
        }

        & > .icon-papa-buy_selected {
          color: #29d087;
          margin-right: 35px;
          cursor: pointer;
        }

        & > .button-delete {
          cursor: pointer;
        }
      }

      & > .right {
        display: flex;
        align-items: flex-end;

        & > .total-qty {
          margin-right: 50px;

          & > span {
            color: #29D087;
            font-weight: bold;
            font-size: 26px;
            padding: 0 10px;
          }
        }

        & > .total-price {
          margin-right: 25px;

          & > span {
            color: #FF5864;
            font-size: 26px;
            font-weight: bold;
          }
        }

        & > a {
          & > .settle-button {
            cursor: pointer;
            width: 140px;
            height: 46px;
            line-height: 46px;
            background: #29d087;
            text-align: center;
            line-height: 46px;
            color: #fff;
            font-size: 18px;
            border-radius: 3px;
            font-weight: 500;
          }

          & > .disable {
            background: #e6e6e6 !important;
          }
        }
      }
    }

    & > .background {
      width: 100%;
      height: 80px;
      background: #fff;
      z-index: 998;
      position: fixed;
      bottom: 0;
      left: 0;
    }
  }

  .no-product {
    width: 1200px;
    height: 600px;
    margin: 30px auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > a {
      & > .action {
        width: 90px;
        height: 36px;
        border: 1px solid #29D087;
        border-radius: 3px;
        color: #29D087;
        font-size: 14px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
      }
    }

    & > .tip {
      color: #999;
      font-size: 14px;
      margin: 20px 0;
    }
  }
</style>
