import * as Api from '@/api/cart/cart.js'
import store from '@/store'
export default {
  data () {
    return {
      selectArr: [],
      selectCourse: [],
      activeCourse: {},
      activeTeacher: {},
      editId: '',
      hoverCourseId: '',
      editAttribute: [],
      editTeacherLevel: [],
      selectAllFlag: false,
      expiredCourse: []
    }
  },
  mounted () {
    let self = this
    new Promise(function (resolve, reject) {
      Api.shoppingCart('get', '', (res) => {
        let products = []
        self.expiredCourse = []
        res.forEach(ele => {
          if (ele.status === 1) {
            products.push(ele)
          } else {
            self.expiredCourse.push(ele)
          }
        })
        store.dispatch('updateCartProduct', {products})
        resolve()
      })
    }).then(() => {
      self.selectProduct('all')
    })
  },
  computed: {
    cartData () {
      return store.state.cart.cart
    },
    totalMoney () {
      if (this.selectCourse.length > 0) {
        let total = 0
        this.selectArr.forEach(id => {
          let selectCourse = this.cartData.find(ele => {
            return ele.id === id
          })
          total += selectCourse.buy_qty * selectCourse.course_attribute.unit_price
        })
        return total
      } else {
        return 0
      }

    }
  },
  methods: {
    getCartCourse () {
      let self = this
      new Promise(function (resolve, reject) {
        Api.shoppingCart('get', '', (res) => {
          let products = []
          self.expiredCourse = []
          res.forEach(ele => {
            if (ele.status === 1) {
              products.push(ele)
            } else {
              self.expiredCourse.push(ele)
            }
          })
          store.dispatch('updateCartProduct', {products})
          resolve(products)
        })
      }).then((products) => {
        // self.selectProduct('all')
        store.dispatch('updateSelectProduct', {products})
      })
    },
    handleChange (index) {
      let item = this.cartData[index]
      let self = this
      let params = {
        buy_qty: item.buy_qty,
        course_subject_id: item.course_subject.id,
        teacher_level_id: item.teacher_level.id
      }
      Api.shoppingCart('put', params, () => {
        self.getCartCourse()
      }, '', item.id)
    },
    deleteProduct (ids) {

    },
    selectProduct (ids) {
      if (ids === 'all') {
        if (this.selectArr.length > 0) {
          this.selectArr = []
          this.selectCourse = []
          this.selectAllFlag = false
        } else {
          this.cartData.forEach(item => {
            this.selectArr.push(item.id)
            this.selectCourse.push(item)
          })
          this.selectAllFlag = true
        }
      } else {
        if (this.selectArr.length > 0) {
          const isInArr = this.selectArr.findIndex(item => {
            return item === ids
          })
          if (isInArr > -1) {
            this.selectArr.splice(isInArr, 1)
            this.selectCourse.splice(isInArr, 1)
          } else {
            const selectItem = this.cartData.find(item => {
              return item.id === ids
            })
            this.selectArr.push(selectItem.id)
            this.selectCourse.push(selectItem)
          }
        } else {
          const selectItem = this.cartData.find(item => {
            return item.id === ids
          })
          this.selectArr.push(selectItem.id)
          this.selectCourse.push(selectItem)
        }
      }
      let products = this.selectCourse
      store.dispatch('updateSelectProduct', {products})
    },
    handleHoverCourse (id) {
      this.hoverCourseId = id
    },
    handleLeveCourse () {
      this.hoverCourseId = ''
    },
    editCourseAttribute (item) {
      this.editId = item.id
      Api.courseDetail('', res => {
        if(res.class_type === this.constant.CLASS_TYPE.PRIVATE){
          this.editAttribute = res.attributes
          this.editTeacherLevel = res.attributes[0].teacher_level
          this.activeCourse.id = item.course_subject.id
          this.activeCourse.name = item.course_subject.name
          this.activeTeacher = item.teacher_level
          this.activeTeacher.unit_price = item.course_attribute.unit_price
        }else{
          res.attributes.forEach(item=>{
            item.id = item.subject_id
          })
          this.editAttribute = res.attributes
          this.activeCourse.id = item.course_subject.id
          this.activeCourse.name = item.course_subject.name
          this.activeTeacher.unit_price = item.course_attribute.unit_price
        }

      }, '', item.course.id, 'v10')
    },
    computedTeacherLevel (item) {
      this.editTeacherLevel = item.teacher_level
      this.activeCourse = item
      this.leastQty = item.teacher_level[0].least_sale_qty
      this.buyNum = item.teacher_level[0].least_sale_qty
      this.unitPrice = item.teacher_level[0].unit_price
      this.activeTeacher = this.editTeacherLevel[0]
    },
    computedLeastQty (item) {
      this.activeTeacher = item
      this.leastQty = item.least_sale_qty
      this.buyNum = item.least_sale_qty
      this.unitPrice = item.unit_price
    },
    submitEdit (item) {
      let course = {
        course_id: item.course.id,
        course_subject_id: this.activeCourse.id,
        teacher_level_id: this.activeTeacher.id,
      }
      if (this.isIncart(course)) {
        this.$message.warning('购物车中已有这门课程~')
      } else {
        let params = {
          buy_qty: item.buy_qty,
          course_subject_id: this.activeCourse.id,
          teacher_level_id: this.activeTeacher.id
        }
        Api.shoppingCart('put', params, () => {
          this.getCartCourse()
          this.editId = ''
        }, '', item.id)
      }
    },
    cancelEdit () {
      this.editId = ''
    },
    deleteCourse (ids) {
      let confirmText = ids ? '确定从购物车中删除这门课程吗？' : '确认删除选中的课程吗？'
      this.$confirm(confirmText, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ids = typeof ids === 'number' ? [ids] : this.selectArr
        let params = {
          cart_ids: ids
        }
        Api.shoppingCart('delete', params, () => {
          this.getCartCourse()
          this.selectArr = this.subArray(this.selectArr, ids)
          ids.forEach(id => {
            this.selectCourse.forEach((ele, index) => {
              if (ele.id === id) {
                this.selectCourse.splice(index, 1)
              }
            })
          })
          ids.forEach(id => {
            this.expiredCourse.forEach((ele, index) => {
              if (ele.id === id) {
                this.expiredCourse.splice(index, 1)
              }
            })
          })

          let products = this.selectCourse
          store.dispatch('updateSelectProduct', {products})
          this.$message.success('删除成功！')
        }, '', '')
      }).catch(() => {
        this.$message.info('已取消删除')
      })
    },
    isIncart (item) {
      return this.cartData.find((product, index) => {
        if (product.course.id === item.course_id) {
          if (product.course_subject.id === item.course_subject_id) {
            if (product.teacher_level.id === item.teacher_level_id) {
              return index
            }
          }
        }
      })
    },
    subArray (arr1, arr2) {
      var set2 = new Set(arr2)
      var subarr = []
      arr1.forEach((ele) => {
        if (!set2.has(ele)) {
          subarr.push(ele)
        }
      })
      return subarr
    }
  }
}
